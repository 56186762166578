import React from 'react';
import './App.css';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {myFetchVariables} from './Helpers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import logo from './logo.png'; // Tell webpack this JS file uses this image
import MapTab from "./MapTab";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Stylesheet from "./Stylesheet";
import Main from "./Main";
import ReCAPTCHA from "react-google-recaptcha";

import ReactGA from 'react-ga';
import Trends from "./Trends";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


ReactGA.initialize('UA-195250815-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const useStyles = makeStyles(Stylesheet);


function Footer(props) {
    const {classes} = props;

    const [formInput, setFormInput] = React.useState({message: ""});

    const recaptchaRef = React.createRef();

    const onSubmit = async (evt) => {
        evt.preventDefault();
        if (formInput["message"] == "") return;
        const token = await recaptchaRef.current.executeAsync();
        let data = {formInput};
        data["token"] = token;

        fetch(process.env.PUBLIC_URL + "/mail.php", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                setFormInput({message: ""});
                alert("Το σχόλιο σας εστάλει.")
            })
            .catch(error => alert("Κάποιο σφάλμα συνέβει."));
    };

    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({[name]: newValue});
    };

    return (
        <footer className={classes.footer}>
            <Container maxWidth="md">
                <Grid container spacing={3}>

                    <Grid item xs={2} md={1}>
                        <img src={logo} style={{marginTop: "10px"}}/>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography variant="h6" className={classes.title}>
                            ΕΠΙΨΥ
                        </Typography>
                        <p className={classes.intro}>
                            Ερευνητικό Πανεπιστημιακό Ινστιτούτο
                            Ψυχικής Υγείας, Νευροεπιστημών και
                            Ιατρικής Ακριβείας «ΚΩΣΤΑΣ
                            ΣΤΕΦΑΝΗΣ»
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" className={classes.title}>
                            Επικοινωνία
                        </Typography>
                        <p className={classes.intro}>
                            Σωρανού του Εφεσίου 2, 115 27 Αθήνα<br/>
                            T: 210 61 70 804-5 | E: secretar@epipsi.gr <br/>
                            URL: <a target={"_blank"} href={"https://www.epipsi.gr/"}>www.epipsi.gr</a> FB: <a
                            target={"_blank"}
                            href={"https://www.facebook.com/EPIPSI.UMHRI"}>www.facebook.com/EPIPSI.UMHRI</a>
                        </p>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography variant="h6" className={classes.title}>
                            Σχόλια
                        </Typography>
                        <form onSubmit={onSubmit}>
                            <FormControl>
                                <Input value={formInput.message} name="message" onChange={handleInput} multiline/>
                            </FormControl>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LdT5rIaAAAAAJM1AgmHE3BSYssc847cfOD4MGT2"
                            />
                            <Button type="submit" style={{marginTop: "10px"}} variant="contained">Αποστολή</Button>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}

function Menu(props) {
    const {classes} = props;
    const location = useLocation();

    return (<Tabs value={location.pathname} nparia-label="simple tabs example"
          className={classes.tabs} centered>
        <Tab value={"/"} label="Γραφήματα" component={Link} to={"/"}/>
        <Tab value={"/maps"} label="Χάρτες"component={Link} to={"/maps"}/>
        <Tab value={"/trends"} label="Διαχρονικές Τάσεις"component={Link} to={"/trends"}/>
    </Tabs>);
}

function App() {
    const classes = useStyles();


    const [loading, setLoading] = React.useState(true);
    const [fields, setFields] = React.useState(null);
    const [filterValues, setFilterValues] = React.useState(null);
    const [categories, setCategories] = React.useState({});
    const filters = ["Έτος", "Φύλο", "Ηλικία", "Νομός", "Γεωγραφικό στρώμα", "Περιφέρεια"];

    React.useEffect(() => {
        document.addEventListener("set-loading", function (event) {
            setLoading(true);
        });

        document.addEventListener("unset-loading", function (event) {
            setLoading(false);
        });

        async function fetchData() {
            try {

                let promises = [fetch(process.env.REACT_APP_SERVICE_URL + "_mappings").then(result => result.json()),
                    fetch(process.env.PUBLIC_URL + "/categories.json").then(result => result.json())
                ];
                for (let i in filters) {
                    promises.push(myFetchVariables(filters[i]));
                }

                const [result1, result2, ...resFilterValues] = await Promise.all(promises);

                setLoading(false);
                setFields(result1.epipsi.mappings.properties);
                let newFilterValues = {};
                for (let i in resFilterValues) {
                    newFilterValues[filters[i]] = resFilterValues[i];
                }

                let newCategories = {};
                for (const categoryIndex in result2) {
                    const category = result2[categoryIndex];
                    if (!result1.epipsi.mappings.properties[category["Μεταβλητή"]]) continue;
                    if (!newCategories[category["Κατηγορία"]]) newCategories[category["Κατηγορία"]] = [];
                    newCategories[category["Κατηγορία"]].push(category["Μεταβλητή"]);
                }
                setCategories(newCategories);
                setFilterValues(newFilterValues);
            } catch
                (err) {
                console.warn(err);
            }
        }
        ;
        fetchData();
    }, []);

    /* <Drawer anchor={"top"} open={state["top"]} onClose={toggleDrawer("top", false)}>
                            <Editor filterValues={filterValues} filters={filters} fields={fields}
                                    charts={[...charts]}
                                    saveCharts={saveCharts}
                                    onClose={toggleDrawer("top", false)}/>
                        </Drawer>*/
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <div className={classes.root}>
                <AppBar position="static" className={classes.bar}>
                    <Container maxWidth="lg">
                        <Toolbar>
                            <img src={logo}/>
                            <div style={{paddingLeft: "25px"}}>
                                <Typography variant="h6" className={classes.title}>
                                    ΔΕΔΟΜΕΝΑ Έρευνας ESPAD-GR
                                </Typography>
                                <p className={classes.intro}>
                                    Πλατφόρμα δημιουργίας γραφημάτων για βασικούς δείκτες σχετικά με τη χρήση ουσιών και
                                    άλλες εξαρτητικές συμπεριφορές. Δεδομένα από την «Πανελλήνια έρευνα για τη χρήση
                                    εξαρτησιογόνων ουσιών και άλλες εξαρτητικές συμπεριφορές» του ΕΠΙΨΥ
                                    ESPAD-GR—ελληνικό
                                    σκέλος της European School Survey Project on Alcohol & other Drugs (www.espad.org)
                                </p>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Container fixed>
                    <Menu classes={classes} />
                </Container>
                <Container fixed style={{paddingTop: "35px", paddingBottom: "35px", minHeight: "calc(100vh - 100px)"}}>
                    <Switch>

                        <Route path="/maps">
                            <MapTab classes={classes} filterValues={filterValues} categories={categories}
                                    setLoading={setLoading}></MapTab>
                        </Route>
                        <Route path="/trends">
                            <Trends classes={classes} filterValues={filterValues} categories={categories}
                                    setLoading={setLoading}></Trends>
                        </Route>
                        <Route path="/">
                            <Main classes={classes} filterValues={filterValues} categories={categories}
                                  setLoading={setLoading}></Main>
                        </Route>
                    </Switch>
                    <canvas style={{position: "absolute", left: "-1920px", top: "-1000px"}}id={"chart-download"} width={1920} height={900}></canvas>
                </Container>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Footer classes={classes}></Footer>
            </div>
        </Router>
    );
}

export default App;
