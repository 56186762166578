import {
    fetchChartsData,
    myFetchVariables,
    populateCharts,
    generateChartOptions,
    generateChartData,
    downloadChart,
    useQuery,
    useQueryVariable, updateQuery
} from "./Helpers";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import {Bar} from "react-chartjs-2";
import chartjssubtitle from './helpers/chartjs-subtitle'
import chartjsdatalabels from 'chartjs-plugin-datalabels'
import {chartColors, categoryLogo, categoryActiveLogo} from "./Settings";
import Categories from "./Categories";
import Filter from "./Filter";
import FiltersPopper from "./FiltersPopper";
import {useHistory} from "react-router-dom";
import Fab from '@material-ui/core/Fab';


const MyChart = (props) => {
    const myRef = React.useRef(null);
    const {chart, chartsData, index} = props;
    const chartData = generateChartData(chart, index, chartsData);
    return (<Grid item key={index} xs={12}><IconButton
                                                       onClick={() => downloadChart(myRef.current.chartInstance)}
                                                       aria-label="delete"
    >
        <GetAppIcon/>
    </IconButton><Bar ref={myRef}
                      data={chartData}
                      options={generateChartOptions(chart, chartData.datasets)}/></Grid>)
};


const defaultValues = {
    "yearIndex": 0,
    "filterState": false,
    "clusterState": false,
    "activeCategories": {"Κάπνισμα": [0]}
};

function Main(props) {
    const {filterValues, classes, categories, setLoading} = props;

    const clusters = ["Φύλο", "Ηλικία", "Νομός", "Γεωγραφικό στρώμα", "Περιφέρεια"];
    const dofilters = ["Έτος", "Νομός", "Περιφέρεια"];

    const history = useHistory();
    const params = useQuery();
    const firstRender = React.useRef(true);
    const filtersChanged = React.useRef(false);


    const saveCharts = (newCharts) => {
        setCharts(newCharts);
    };

    const handleAccordionChange = (category) => (event, isExpanded) => {
        setAccordionExpanded(isExpanded ? category : false);
    };

    const handleMenuItemClick = (event, index, category) => {
        setSelectedIndex(index);
        setActiveCategory(category);
        setOpen(false);
    };

    const handleFilterToggle = (event, filter, type) => {
        setOpen(open !== false && open["type"] == type && open["item"] == filter ? false : {
            type: type,
            item: filter,
            element: event.target
        });
    };

    const handleClose = (event) => {
        if (open !== false && open.element && open.element.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleFilterMenuItemClick = (event, index, filter) => {

        if (filter.item != "Έτος") {
            if (filter.type == "filter") {
                if (filterState === false || filter.item != filterState["item"] || filterState["indexes"].indexOf(index) === -1) {
                    setFilterState({[filter.item]: [index]});
                } else {
                    setFilterState(false);
                }
            } else {
                if (clusterState === false || filter.item != clusterState["item"]) {
                    setClusterState({
                        "indexes": [index],
                        "item": filter.item
                    });
                } else {
                    let newIndexes = [...clusterState["indexes"]];
                    const position = newIndexes.indexOf(index);
                    if (position === -1) {
                        newIndexes.push(index);
                    } else {
                        newIndexes.splice(position, 1);
                    }
                    setClusterState(!newIndexes.length ? false : {
                        "indexes": newIndexes,
                        "item": filter.item
                    });
                }
            }
        } else {
            setYearIndex(index);
        }
        setOpen(false);
    };

    const selectAll = (event, filter, type) => {
        if (type == "filter") {
            setFilterState({
                "indexes": [...filterValues[filter].keys()],
                "item": filter
            });
        } else if (type == "cluster") {
            setClusterState({
                "indexes": [...filterValues[filter].keys()],
                "item": filter
            });
        }
    };

    const removeAll = (event, filter, type) => {
        if (type == "filter") {
            setFilterState(false);
        } else if (type == "cluster") {
            setClusterState(false);
        }
    };

    const handleRestoreDefaults = () => {
        setFilterState(defaultValues.filterState);
        setClusterState(defaultValues.clusterState);
        setActiveCategory(Object.keys(defaultValues.activeCategories)[0]);
        setAccordionExpanded(Object.keys(defaultValues.activeCategories)[0]);
        setSelectedIndex(defaultValues.activeCategories[Object.keys(defaultValues.activeCategories)[0]][0])
        setYearIndex(defaultValues.yearIndex);
    };

    function checkFilterChange() {
        return defaultValues["yearIndex"] != yearIndex
        || JSON.stringify(filterState) != JSON.stringify(defaultValues.filterState)
        || JSON.stringify(clusterState) != JSON.stringify(defaultValues.clusterState)
        || JSON.stringify({[activeCategory]: [selectedIndex]}) != JSON.stringify(defaultValues.activeCategories);
    }

    const [open, setOpen] = React.useState(false);
    const [yearIndex, setYearIndex] = React.useState(useQueryVariable("yearIndex", 0, "integer"));


    const [filterState, setFilterState] = React.useState(useQueryVariable("filterState", false, "object"));
    const inputClusterState = useQueryVariable("clusterState", false, "object");
    const [clusterState, setClusterState] = React.useState(inputClusterState !== false ? {"item": Object.keys(inputClusterState)[0],  "indexes": inputClusterState[Object.keys(inputClusterState)[0]]} : false);

    const activeCategories = useQueryVariable("activeCategories", {'Κάπνισμα': [0]}, "object");
    const [activeCategory, setActiveCategory] = React.useState(Object.keys(activeCategories)[0]);
    const [accordionExpanded, setAccordionExpanded] = React.useState(Object.keys(activeCategories)[0]);
    const [selectedIndex, setSelectedIndex] = React.useState(activeCategories[Object.keys(activeCategories)[0]][0]);

    React.useEffect(() => {
        if (filterValues === null) return;

        async function fetchData() {
            setLoading(true);
            const variable = categories[activeCategory][selectedIndex];
            const [variables] = await Promise.all([myFetchVariables(variable)]);

            setLoading(false);

            const [newCharts, newTitle] = populateCharts(["Ηλικία", "Φύλο", "Γεωγραφικό στρώμα"], true, {[variable]: variables}, filterValues['Έτος'][yearIndex], clusterState, filterState, filterValues);


            setCharts(newCharts);
            setTitle(newTitle);
        }

        fetchData();
    }, [JSON.stringify(filterValues), activeCategory, selectedIndex, JSON.stringify(clusterState), JSON.stringify(filterState), yearIndex]);

    React.useEffect(() => {
        filtersChanged.current = checkFilterChange();

        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        let data = {};
        data["activeCategories_" + activeCategory] = selectedIndex;
        for (let filter in filterState) {
            data["filterState_" + filter] = filterState[filter].join(",");
        }
        if (clusterState) {
            data["clusterState_" + clusterState["item"]] = clusterState["indexes"].join(",");
        }
        data["yearIndex"] = yearIndex;
        updateQuery(history, params, data);
    }, [JSON.stringify(filterState), JSON.stringify(clusterState), activeCategory, selectedIndex, yearIndex]);

    const [charts, setCharts] = React.useState([]);
    const [title, setTitle] = React.useState(null);
    const [chartsData, setChartsData] = React.useState(null);

    React.useEffect(() => {
        return fetchChartsData(charts, setLoading, setChartsData);
    }, [JSON.stringify(charts)]);

    return (<React.Fragment><Grid container spacing={3}>
            <Grid item md={3} xs={12}>
                <Categories
                    showRemoveFilters={filtersChanged.current}
                    accordionExpanded={accordionExpanded}
                    activeCategories={{[activeCategory]: [selectedIndex]}}
                    categories={categories}
                    classes={classes}
                    handleAccordionChange={handleAccordionChange}
                    handleMenuItemClick={handleMenuItemClick}
                    restoreDefaults={handleRestoreDefaults}
                />
            </Grid>
            <Grid item md={9} xs={12}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Grid container justify="space-between" spacing={5} className={classes.filterWrapper}>
                            <Grid item sm={2} xs={12}>Φίλτρα</Grid>
                            <Grid item sm={10} xs={12} style={{paddingTop: 0}}>
                                {filterValues !== null && Object.keys(filterValues).map((filter, index) => {
                                    if (dofilters.includes(filter)) return (
                                        <Filter
                                            multiple={false}
                                            key={index} filter={filter}
                                                active={filter == "Έτος" || (filterState && filterState[filter])}
                                                all={false}
                                                onClick={(event) => handleFilterToggle(event, filter, "filter")}
                                                selectAll={false}
                                                removeAll={false}
                                        />
                                    )
                                })
                                }
                            </Grid>
                        </Grid>
                        <Grid container justify="space-between" spacing={5} className={classes.filterWrapper}>
                            <Grid item sm={2} xs={12}>Συγκριτικά</Grid>

                            <Grid item sm={10} xs={12} style={{paddingTop: 0}}>
                                {filterValues !== null && Object.keys(filterValues).map((filter, index) => {
                                    if (clusters.includes(filter)) return (
                                        <Filter
                                            multiple={true}
                                            active={(clusterState && filter == clusterState["item"])}
                                                key={index}
                                                filter={filter}
                                                all={(clusterState && clusterState["item"] == filter && filterValues[filter].length == clusterState["indexes"].length)}

                                                onClick={(event) => handleFilterToggle(event, filter, "cluster")}
                                                selectAll={(event) => selectAll(event, filter, "cluster")}
                                                removeAll={(event) => removeAll(event, filter, "cluster")}
                                        />);
                                    return null;
                                })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Typography component="div"
                            className={classes.chartWrapper}>
                    <h4 className={classes.heading}><img
                        src={categoryLogo[activeCategory]}/>{title}
                    </h4>
                    <Grid container spacing={5}>
                        {charts.map((chart, index) => {
                                return (
                                    <MyChart index={index} key={index} chart={chart}
                                             chartsData={chartsData}/>
                                )
                            }
                        )}
                    </Grid>
                </Typography>

                {(filterValues && (filterValues['Έτος'][yearIndex] === 2015 || filterValues['Έτος'][yearIndex] === 2009)) && (<p>* Τα δεδομένα αφορούν μόνο αγόρια</p>)}
            </Grid>
        </Grid>
            <FiltersPopper open={open} handleClose={handleClose} filterValues={filterValues} filterState={filterState} clusterState={clusterState} yearIndex={yearIndex} handleFilterMenuItemClick={handleFilterMenuItemClick} />
    </React.Fragment>
  );
}

export default Main;