import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {categoryActiveLogo, categoryLogo} from "./Settings";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';


function Categories(props) {
    const {categories, classes, accordionExpanded, handleAccordionChange, handleMenuItemClick, activeCategories, showCheckbox, showRemoveFilters, restoreDefaults} = props;
    return (<Typography component="div" style={{position: "relative"}}>
        {Object.keys(categories).map((category, index) => (
            <Accordion key={category} className={classes.accordion}
                       expanded={accordionExpanded === category}
                       onChange={handleAccordionChange(category)}>
                <AccordionSummary expandIcon={(accordionExpanded === category &&
                    <RemoveCircleIcon/>) || (accordionExpanded !== category &&
                    <AddCircleIcon/>)}>
                    <div className={"image-wrapper"}>
                        <img className={"default"} src={categoryLogo[category]}/>
                        <img className={"active"} src={categoryActiveLogo[category]}/>
                    </div>
                    <div className={"title-wrapper"}>{category}</div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>

                    <MenuList>
                        {categories[category].map((option, index) => {
                            const isActive = activeCategories && activeCategories[category] && activeCategories[category].indexOf(index) !== -1;
                            const checkbox = showCheckbox ? (<Checkbox checked={!!isActive}/>) : null
                            return (
                                <Tooltip key={option + "-" + index}
                                         title={showCheckbox ? "Επιλέγξτε τους δείκτες που επιθυμείτε" : "Επιλέξτε έναν δείκτη"}>

                                    <MenuItem
                                        selected={isActive}
                                        onClick={(event) => handleMenuItemClick(event, index, category)}
                                    >
                                        {checkbox}
                                        {option}
                                    </MenuItem>
                                </Tooltip>

                            )
                        })}
                    </MenuList>

                </AccordionDetails>
            </Accordion>
        ))
        }
        {showRemoveFilters === true && <Fab onClick={restoreDefaults} className={classes.removeFilters} aria-label="like" color={"secondary"} variant="extended">
            <RotateLeftIcon/> Επαναφορά προεπιλογών
        </Fab>}
    </Typography>);
}

export default Categories;