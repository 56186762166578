import {
    fetchChartsData,
    myFetchVariables,
    populateCharts,
    downloadChart,
    generateChartData,
    generateChartOptions,
    useQueryVariable, updateQuery, useQuery
} from "./Helpers";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import {Line} from "react-chartjs-2";
import chartjssubtitle from './helpers/chartjs-subtitle'
import chartjsdatalabels from 'chartjs-plugin-datalabels'
import {chartColorsArraySingle} from "./Settings";
import Categories from "./Categories";
import Filter from "./Filter";
import FiltersPopper from "./FiltersPopper";
import {useHistory} from 'react-router-dom';

const MyChart = (props) => {
    const myRef = React.useRef(null);
    const {chart, chartsData, index, activeCategories, categories, filterValues, clusterState} = props;
    let mychart = {...chart};
    mychart.title = "Διαχρονικές Τάσεις";
    let chartData = generateChartData(mychart, index, chartsData, true);

    let datasets = [];
    let values = [];
    let labels = [...filterValues['Έτος']].sort();


    let variableI = 0;

    for (let category in activeCategories) {
        for (let index in activeCategories[category]) {
            if (clusterState) {
                for (let c in clusterState["indexes"]) {
                    values = [];
                    for (let i in chartData.datasets) {
                        values.push(chartData.datasets[i].data[c] == null ? null : [chartData.datasets[i].data[c]]);
                    }

                    datasets.push({
                        label: categories[category][activeCategories[category][index]] + (clusterState ? ", " + clusterState["item"]  + ": " + filterValues[clusterState["item"]][c]: ""),
                        data: values,
                        backgroundColor: chartColorsArraySingle[c % chartColorsArraySingle.length],
                        borderColor: chartColorsArraySingle[c % chartColorsArraySingle.length],
                        spanGaps: false,
                        fill: false,
                    });
                }
            } else {
                values = [];
                for (let i in chartData.datasets) {
                    values.push(chartData.datasets[i].data[variableI] == null ? null : [chartData.datasets[i].data[variableI]]);
                }

                datasets.push({
                    label: categories[category][activeCategories[category][index]] + (clusterState ? ", " + clusterState["item"] : ""),
                    data: values,
                    backgroundColor: chartColorsArraySingle[variableI % chartColorsArraySingle.length],
                    borderColor: chartColorsArraySingle[variableI % chartColorsArraySingle.length],
                    spanGaps: false,
                    fill: false,
                });
            }
            variableI++;
        }
    }
    chartData = {labels: labels, datasets: datasets};
    return (<Grid item key={index} xs={12}><IconButton
        onClick={() => downloadChart(myRef.current.chartInstance)}
        aria-label="delete"
    >
        <GetAppIcon/>
    </IconButton><Line ref={myRef}
                       data={chartData}
                       options={generateChartOptions(mychart, chartData.datasets, "line")}/></Grid>)
};

const defaultValues = {
    "yearIndex": 0,
    "filterState": false,
    "clusterState": false,
    "activeCategories": {"Κάπνισμα": [0]}
};

function Trends(props) {
    const {filterValues, classes, categories, setLoading} = props;
    const activeCategoriesLength = React.useRef(1);
    const firstRender = React.useRef(true);
    const filtersChanged = React.useRef(false);
    const history = useHistory();
    const params = useQuery();
    const dofilters = ["Φύλο", "Ηλικία", "Γεωγραφικό στρώμα", "Νομός", "Περιφέρεια"];
    const clusters = ["Φύλο", "Ηλικία", "Νομός", "Γεωγραφικό στρώμα", "Περιφέρεια"];

    const handleAccordionChange = (category) => (event, isExpanded) => {
        setAccordionExpanded(isExpanded ? category : false);
    };

    const handleMenuItemClick = (event, index, category) => {
        if (activeCategories === false) {
            setActiveCategories({[category]: [index]});
        } else if (!activeCategories[category]) {
            setActiveCategories({...activeCategories, [category]: [index]});
        } else if (activeCategories[category].indexOf(index) === -1) {
            setActiveCategories({...activeCategories, [category]: [...activeCategories[category], index]});
        } else {
            let newActiveCategories = {...activeCategories};
            newActiveCategories[category].splice(newActiveCategories[category].indexOf(index), 1);
            if (newActiveCategories[category].length === 0) delete newActiveCategories[category];
            if (Object.keys(newActiveCategories).length === 0) newActiveCategories = {'Κάπνισμα': [0]};
            setActiveCategories(newActiveCategories);
        }
        setOpen(false);
    };

    const handleFilterToggle = (event, filter, type) => {
        setOpen(open !== false && open["type"] == type && open["item"] == filter ? false : {
            type: type,
            item: filter,
            element: event.target
        });
    };

    const handleClose = (event) => {
        if (open !== false && open.element && open.element.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleFilterMenuItemClick = (event, index, filter) => {

        if (filter.item != "Έτος") {
            if (filter.type == "filter") {
                if (filterState === false) {
                    setFilterState({
                        [filter.item]: [index]
                    });
                } else if (!filterState[filter.item]) {
                    setFilterState({
                        ...filterState,
                        [filter.item]: [index]
                    });
                } else if (filterState[filter.item].indexOf(index) === -1) {
                    //let {newFilterState} = {...filterState};
                    //newFilterState[filter.item] = [...filterState[filter.item], index];

                    if (filter.item == "Ηλικία") {
                        setFilterState({
                            ...filterState,
                            [filter.item]: [index]
                        });
                    } else {
                        setFilterState({
                            ...filterState,
                            [filter.item]: [...filterState[filter.item], index]
                        });
                    }
                } else {
                    let newFilterState = {...filterState};
                    newFilterState[filter.item].splice(filterState[filter.item].indexOf(index), 1);
                    if (newFilterState[filter.item].length === 0) delete newFilterState[filter.item];
                    if (Object.keys(newFilterState).length === 0) newFilterState = false;
                    setFilterState(newFilterState);
                }
            } else {
                if (clusterState === false || filter.item != clusterState["item"]) {
                    setClusterState({
                        "indexes": [index],
                        "item": filter.item
                    });
                } else {
                    let newIndexes = [...clusterState["indexes"]];
                    const position = newIndexes.indexOf(index);
                    if (position === -1) {
                        newIndexes.push(index);
                    } else {
                        newIndexes.splice(position, 1);
                    }
                    setClusterState(!newIndexes.length ? false : {
                        "indexes": newIndexes,
                        "item": filter.item
                    });
                }
            }
        }
        setOpen(false);
    };

    const selectAll = (event, filter, type) => {
        if (type == "filter") {
            setFilterState({
                "indexes": [...filterValues[filter].keys()],
                "item": filter
            });
        } else if (type == "cluster") {
            setClusterState({
                "indexes": [...filterValues[filter].keys()],
                "item": filter
            });
        }
    };

    const removeAll = (event, filter, type) => {
        if (type == "filter") {
            let newFilterState = {...filterState};
            delete newFilterState[filter];
            if (Object.keys(newFilterState).length == 0) newFilterState = false;
            setFilterState(newFilterState);
        } else if (type == "cluster") {
            setClusterState(false);
        }
    };


    const [open, setOpen] = React.useState(false);

    function checkFilterChange() {
        return JSON.stringify(filterState) != JSON.stringify(defaultValues.filterState)
            || JSON.stringify(clusterState) != JSON.stringify(defaultValues.clusterState)
            || JSON.stringify(activeCategories) != JSON.stringify(defaultValues.activeCategories);
    }

    const [filterState, setFilterState] = React.useState(useQueryVariable("filterState", false, "object"));
    const inputClusterState = useQueryVariable("clusterState", false, "object");
    const [clusterState, setClusterState] = React.useState(inputClusterState !== false ? {"item": Object.keys(inputClusterState)[0],  "indexes": inputClusterState[Object.keys(inputClusterState)[0]]} : false);

    const inputActiveCategories = useQueryVariable("activeCategories", {'Κάπνισμα': [0]}, "object");
    const [activeCategories, setActiveCategories] = React.useState(inputActiveCategories);
    const [accordionExpanded, setAccordionExpanded] = React.useState(Object.keys(inputActiveCategories)[0]);

    const handleRestoreDefaults = () => {
        setClusterState(defaultValues.clusterState);
        setFilterState(defaultValues.filterState);
        setActiveCategories(defaultValues.activeCategories);
        setAccordionExpanded(Object.keys(defaultValues.activeCategories)[0]);
    };

    React.useEffect(() => {
        activeCategoriesLength.current = 0;
        for (let category in activeCategories) {
            activeCategoriesLength.current = activeCategoriesLength.current + activeCategories[category].length;
        }
        if (activeCategoriesLength.current > 1) setClusterState(false);
    }, [
        JSON.stringify(activeCategories)
    ]);

    React.useEffect(() => {
        if (filterValues === null) return;

        async function fetchData() {
            setLoading(true);
            let variables = {};
            let temp = [];
            let premises = [];
            for (let category in activeCategories) {
                for (let index in activeCategories[category]) {
                    temp.push(categories[category][activeCategories[category][index]]);
                    premises.push(myFetchVariables(categories[category][activeCategories[category][index]]));
                }
            }

            const results = await Promise.all(premises);
            for (let i in results) {
                variables[temp[i]] = results[i];
            }
            setLoading(false);
            const [newCharts, newTitle] = populateCharts(["Έτος"], false, variables, null, clusterState, filterState, filterValues);


            setCharts(newCharts);
            setTitle(newTitle);
        }

        fetchData();
    }, [JSON.stringify(filterValues), JSON.stringify(activeCategories), JSON.stringify(clusterState), JSON.stringify(filterState)]);


    React.useEffect(() => {
        filtersChanged.current = checkFilterChange();
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        let data = {};
        for (let activeCategory in activeCategories) {
            data["activeCategories_" + activeCategory] = activeCategories[activeCategory].join(",");
        }
        for (let filter in filterState) {
            data["filterState_" + filter] = filterState[filter].join(",");
        }
        if (clusterState) {
            data["clusterState_" + clusterState["item"]] = clusterState["indexes"].join(",");
        }
        updateQuery(history, params, data);
    }, [JSON.stringify(filterState), JSON.stringify(clusterState), JSON.stringify(activeCategories)]);


    const [charts, setCharts] = React.useState([]);
    const [title, setTitle] = React.useState(null);
    const [chartsData, setChartsData] = React.useState(null);

    React.useEffect(() => {
        return fetchChartsData(charts, setLoading, setChartsData);
    }, [JSON.stringify(charts)]);

    return (<React.Fragment><Grid container spacing={3}>
            <Grid item md={3} xs={12}>
                <Categories
                    showRemoveFilters={filtersChanged.current}
                    showCheckbox={true}
                    accordionExpanded={accordionExpanded} activeCategories={activeCategories} categories={categories}
                    classes={classes} handleAccordionChange={handleAccordionChange}
                    handleMenuItemClick={handleMenuItemClick}
                    restoreDefaults={handleRestoreDefaults}
                />
            </Grid>
            <Grid item md={9} xs={12}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Grid container justify="space-between" spacing={5} className={classes.filterWrapper}>
                            <Grid item sm={2} xs={12}>Φίλτρα</Grid>
                            <Grid item sm={10} xs={12} style={{paddingTop: 0}}>
                                {filterValues !== null && Object.keys(filterValues).map((filter, index) => {
                                    if (dofilters.includes(filter)) return (
                                        <Filter
                                            multiple={filter != 'Έτος' && filter != "Ηλικία"} key={index} filter={filter}
                                            active={filter == "Έτος" || (filterState && filterState[filter])}
                                            all={false}
                                            onClick={(event) => handleFilterToggle(event, filter, "filter")}
                                            selectAll={false}
                                            removeAll={(event) => removeAll(event, filter, "filter")}
                                        />
                                    )
                                })
                                }
                            </Grid>
                        </Grid>
                        <Grid container justify="space-between" spacing={5} className={classes.filterWrapper}>
                            <Grid item sm={2} xs={12}>Συγκριτικά</Grid>

                            <Grid item sm={10} xs={12} style={{paddingTop: 0}}>
                                {filterValues !== null && Object.keys(filterValues).map((filter, index) => {
                                    if (clusters.includes(filter)) return (
                                        <Filter
                                            disabled={activeCategoriesLength.current > 1}
                                            multiple={true}
                                            active={(clusterState && filter == clusterState["item"])}
                                            key={index}
                                            filter={filter}
                                            all={(clusterState && clusterState["item"] == filter && filterValues[filter].length == clusterState["indexes"].length)}

                                            onClick={(event) => handleFilterToggle(event, filter, "cluster")}
                                            selectAll={(event) => selectAll(event, filter, "cluster")}
                                            removeAll={(event) => removeAll(event, filter, "cluster")}
                                        />);
                                    return null;
                                })
                                }
                            </Grid>
                        </Grid>


                        <Typography component="div"
                                    className={classes.chartWrapper}>
                            <Grid container spacing={5}>
                                {charts.map((chart, index) => {
                                        return (
                                            <MyChart filterValues={filterValues} index={index} key={index} chart={chart}
                                                     chartsData={chartsData} categories={categories}
                                                     activeCategories={activeCategories} clusterState={clusterState}/>
                                        )
                                    }
                                )}
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
            <FiltersPopper open={open} handleClose={handleClose} filterValues={filterValues} filterState={filterState}
                           clusterState={clusterState}
                           handleFilterMenuItemClick={handleFilterMenuItemClick}/>
        </React.Fragment>
    );
}

export default Trends;