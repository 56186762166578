export const categoryLogo = {
    'Κάπνισμα': process.env.PUBLIC_URL + '/icons/smoking.png',
    'Καφές και ενεργειακά ποτά': process.env.PUBLIC_URL + '/icons/coffee.png',
    'Αλκοόλ': process.env.PUBLIC_URL + '/icons/drink.png',
    'Παράνομες ουσίες': process.env.PUBLIC_URL + '/icons/cannabis.png',
    'Μέσα κοινωνικής δικτύωσης': process.env.PUBLIC_URL + '/icons/social.png',
    'Ηλεκτρονικά παιχνίδια': process.env.PUBLIC_URL + '/icons/gaming.png',
    'Στοιχηματισμός': process.env.PUBLIC_URL + '/icons/stoixima.png'
};

export const categoryActiveLogo = {
    'Κάπνισμα': process.env.PUBLIC_URL + '/icons/smoking-white.png',
    'Καφές και ενεργειακά ποτά': process.env.PUBLIC_URL + '/icons/coffee-white.png',
    'Αλκοόλ': process.env.PUBLIC_URL + '/icons/drink-white.png',
    'Παράνομες ουσίες': process.env.PUBLIC_URL + '/icons/cannabis-white.png',
    'Μέσα κοινωνικής δικτύωσης': process.env.PUBLIC_URL + '/icons/social-white.png',
    'Ηλεκτρονικά παιχνίδια': process.env.PUBLIC_URL + '/icons/gaming-white.png',
    'Στοιχηματισμός': process.env.PUBLIC_URL + '/icons/stoixima-white.png'
};

export const chartColors = {
    "Ηλικία": {
        '16': '#ebb28a',
        '17': '#b96029',
        '18': '#7b401b'
    },
    "Φύλο": {
        'Κορίτσι': '#fbe6a5',
        'Αγόρι': '#b8912f'
    },
    //#7a611c
    "Τάξη": {
        'Α Λυκείου': '#863d9b',
        'Β Λυκείου': '#f68e56',
        'Γ Λυκείου': '#7cc576',
        'Γ Γυμνασίου': '#bd8cbf',
    },
    "Γεωγραφικό στρώμα": {
        'Ν.Αττικής': '#ebb28a',
        'Ν.Θεσσαλονίκης': '#b96029',
        'Λοιποί Νομοί': '#7b401b'
    }
};

export const chartColorsArraySingle = [
    '#ebb28a',
    '#b96029',
    '#7b401b',
    '#fbe6a5',
    '#b8912f',
    '#7a611c'
];

export const chartColorsArray = {
    'Κάπνισμα': [

        '#f2f2f2',
        '#deeaf6',
        '#bcd6ee',
        '#9cc2e5',
        '#2e75b5',
        '#1e4e79',
        '#002060'

    ],

    'Αλκοόλ': [

        '#f2f2f2',
        '#e2efd9',
        '#c5e1b3',
        '#a8d08d',
        '#548135',
        '#385623',
        '#003300'

    ],

    'Παράνομες ουσίες': [

        '#f2f2f2',
        '#fbe5d5',
        '#f7caac',
        '#f4b083',
        '#c55a12',
        '#833c0c',
        '#800000'

    ],

    'Καφές και ενεργειακά ποτά': [

        '#f2f2f2',
        '#fef2cb',
        '#ffe598',
        '#fed964',
        '#bf9001',
        '#806001',
        '#663300'

    ],
    'Μέσα κοινωνικής δικτύωσης': [

        '#f2f2f2',
        '#ffccff',
        '#ff99ff',
        '#ff66ff',
        '#cc00cc',
        '#660066',
        '#660033'

    ],
    'Ηλεκτρονικά παιχνίδια': [

        '#f2f2f2',
        '#ffcc99',
        '#ff6600',
        '#ff6600',
        '#ff0000',
        '#cd0000',
        '#800000'

    ],
    'Στοιχηματισμός': [

        '#f2f2f2',
        '#ccffcc',
        '#66ff66',
        '#33cc33',
        '#008001',
        '#015a10',
        '#000000'

    ]

};

export const tooltipsSingle = {
    "Έτος": 'Επιλέξτε ένα Έτος',
    "Ηλικία": 'Επιλέξτε την Ηλικία',
    "Νομός": 'Επιλέξτε ένα Νομό',
    "Περιφέρεια": 'Επιλέξτε μία Περιφέρεια',
};

export const tooltipsMultiple = {
    "Φύλο": 'Επιλέγξτε τα Φύλα',
    "Ηλικία": 'Επιλέξτε τις Ηλικίες',
    "Νομός": 'Επιλέξτε τους Νομούς',
    "Περιφέρεια": 'Επιλέξτε τις Περιφέρειες',
    "Γεωγραφικό στρώμα": 'Επιλέξτε τα Γεωγραφικά στρώματα'
};