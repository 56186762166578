import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DoneAll from "@material-ui/icons/DoneAll";
import Clear from "@material-ui/icons/Clear";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import {tooltipsMultiple, tooltipsSingle} from "./Settings";

export default function Filter(props) {
    const {filter, active, all, onClick, selectAll, removeAll, multiple, disabled} = props;

    const buttons = [];

    if (filter != 'Έτος' && selectAll != false) buttons.push(<Tooltip key={"delete"} title="Επιλογή όλων"
                                                                      aria-label="Επιλογή όλων">
        <IconButton size="small" onClick={selectAll} aria-label="Επιλογή όλων" disabled={all}>
            <DoneAll/>
        </IconButton>
    </Tooltip>);
    if (filter != 'Έτος' && removeAll != false)
        buttons.push(<Tooltip key={"remove"} title="Αφάιρεση όλων" aria-label="Αφάιρεση όλων">
            <IconButton size="small" onClick={removeAll} aria-label="Αφάιρεση όλων" disabled={!active}>
                <Clear/>
            </IconButton>
        </Tooltip>);

    return (<Tooltip placement={"top"} title={multiple ? tooltipsMultiple[filter] : tooltipsSingle[filter]}>
        <ButtonGroup disabled={!!props.disabled} variant="contained"
                         aria-label="split button"
                         style={{marginRight: "12px", marginTop: "12px"}}>
        <Button color={active ? "primary" : "default"} onClick={onClick}>{filter}</Button>
        <Button
            color={active ? "primary" : "default"}
            size="small"
            //aria-controls={open ? 'split-button-menu' : undefined}
            //aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={onClick}
        >
            <ArrowDropDownIcon/>

        </Button>
        {buttons}
    </ButtonGroup></Tooltip>);
}