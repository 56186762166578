import {MapContainer} from 'react-leaflet'
import Choropleth from "react-leaflet-choropleth";
import React from "react";
import 'leaflet/dist/leaflet.css'
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
    downloadChart,
    fetchChartsData,
    floatFormatter,
    myFetchVariables,
    populateCharts,
    useQueryVariable,
    updateQuery,
    useQuery
} from "./Helpers";
import Categories from "./Categories";
import Filter from "./Filter";
import FiltersPopper from "./FiltersPopper";
import {categoryLogo, chartColorsArray} from "./Settings";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
/* in ES 6 */
import domtoimage from 'dom-to-image';
import {saveAs} from 'file-saver';
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';

const mapStyle = {
    fillColor: 'grey',
    weight: 2,
    opacity: 1,
    color: 'white',
    dashArray: '3',
    fillOpacity: 1
};


function downloadMap() {
    const element = document.getElementById('my-map-container');
    const elements = element.getElementsByClassName("hideOnSave");
    for (let i = 0; i < elements.length; i++) {
        elements[i].style.visibility = "hidden";
    }
    domtoimage.toBlob(element)
        .then(function (blob) {
            saveAs(blob, 'map.png');
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.visibility = "";
            }
        });
}

function Gradient(props) {
    const {scale, colors} = props;
    const stepColor = 100 / colors.length;
    return (<div>
        <div>%</div>
        <svg width="170.265625" height="27.8125">
            <g>
                <rect width="170" height="13" style={{fill: "url('#g-k8sh')"}}></rect>
                <g className="labels" transform="translate(0,28)">
                    <text className="min" transform="translate(0,0)">
                        <tspan x="0" dy="0">{scale.min}</tspan>
                    </text>
                    <text className="max" transform="translate(146,0)">
                        <tspan x="0" dy="0">{scale.max}</tspan>
                    </text>
                </g>
            </g>
            <defs>
                <linearGradient id="g-k8sh" y2="0" x2="1">
                    {colors.map((color, index) =>
                        <stop offset={stepColor * index + "%"} stop-color={color}></stop>
                    )}
                </linearGradient>
            </defs>
        </svg>
    </div>);
}

const defaultValues = {
    "yearIndex": 0,
    "filterState": false,
    "activeCategories": {"Κάπνισμα": [0]},
    "mapType": "Νομός"
};

function MapTab(props) {
    const {categories, classes, setLoading, filterValues} = props;

    const dofilters = ["Έτος", "Ηλικία", "Φύλο"];
    const scale = React.useRef({min: 0, max: 0});
    const [geojson, setGeojson] = React.useState(null);
    const [geodata, setGeodata] = React.useState(null);
    const [mapType, setMapType] = React.useState(useQueryVariable("mapType", "Νομός"));

    const [geojsonLoaded, setGeojsonLoaded] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    function checkFilterChange() {
        return defaultValues["yearIndex"] != yearIndex
            || defaultValues["mapType"] != mapType
            || JSON.stringify(filterState) != JSON.stringify(defaultValues.filterState)
            || JSON.stringify({[activeCategory]: [selectedIndex]}) != JSON.stringify(defaultValues.activeCategories);
    }

    const activeCategories = useQueryVariable("activeCategories", {'Κάπνισμα': [0]}, "object");
    const [activeCategory, setActiveCategory] = React.useState(Object.keys(activeCategories)[0]);
    const [accordionExpanded, setAccordionExpanded] = React.useState(Object.keys(activeCategories)[0]);
    const [selectedIndex, setSelectedIndex] = React.useState(activeCategories[Object.keys(activeCategories)[0]][0]);
    const [yearIndex, setYearIndex] = React.useState(useQueryVariable("yearIndex", 0, "integer"));
    const [filterState, setFilterState] = React.useState(useQueryVariable("filterState", false, "object"));

    const [charts, setCharts] = React.useState([]);
    const [title, setTitle] = React.useState(null);
    const [chartsData, setChartsData] = React.useState(null);

    const choropleth = React.useRef(null);

    const firstRender = React.useRef(true);
    const filtersChanged = React.useRef(false);

    const history = useHistory();
    const params = useQuery();

    const handleRestoreDefaults = () => {
        setFilterState(defaultValues.filterState);
        setActiveCategory(Object.keys(defaultValues.activeCategories)[0]);
        setAccordionExpanded(Object.keys(defaultValues.activeCategories)[0]);
        setSelectedIndex(defaultValues.activeCategories[Object.keys(defaultValues.activeCategories)[0]][0])
        setYearIndex(defaultValues.yearIndex);
    };

    const handleClose = (event) => {
        if (open !== false && open.element && open.element.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleFilterMenuItemClick = (event, index, filter) => {

        if (filter.item != "Έτος") {
            if (filter.type == "filter") {
                if (filterState === false) {
                    setFilterState({
                        [filter.item]: [index]
                    });
                } else if (!filterState[filter.item]) {
                    setFilterState({
                        ...filterState,
                        [filter.item]: [index]
                    });
                } else if (filterState[filter.item].indexOf(index) === -1) {
                    //let {newFilterState} = {...filterState};
                    //newFilterState[filter.item] = [...filterState[filter.item], index];
                    setFilterState({
                        ...filterState,
                        [filter.item]: [...filterState[filter.item], index]
                    });
                } else {
                    let newFilterState = {...filterState};
                    newFilterState[filter.item].splice(filterState[filter.item].indexOf(index), 1);
                    if (newFilterState[filter.item].length === 0) delete newFilterState[filter.item];
                    if (Object.keys(newFilterState).length === 0) newFilterState = false;
                    setFilterState(newFilterState);
                }
            }
        } else {
            setYearIndex(index);
        }
        setOpen(false);
    };

    const handleAccordionChange = (category) => (event, isExpanded) => {
        setAccordionExpanded(isExpanded ? category : false);
    };

    const handleMenuItemClick = (event, index, category) => {
        setSelectedIndex(index);
        setActiveCategory(category);
        setOpen(false);
    };

    const selectAll = (event, filter, type) => {
        if (type == "filter") {
            setFilterState({
                "indexes": [...filterValues[filter].keys()],
                "item": filter
            });
        }
    };

    const removeAll = (event, filter, type) => {
        if (type == "filter") {
            let newFilterState = {...filterState};
            delete newFilterState[filter];
            if (Object.keys(newFilterState).length == 0) newFilterState = false;
            setFilterState(newFilterState);
        }
    };

    const handleChange = (event, value) => {
        setGeojsonLoaded(false);
        setMapType(value);
    };

    const handleFilterToggle = (event, filter, type) => {
        setOpen(open !== false && open["type"] == type && open["item"] == filter ? false : {
            type: type,
            item: filter,
            element: event.target
        });
    };

    React.useEffect(() => {
        fetch(process.env.PUBLIC_URL + "/greece_" + (mapType == "Νομός" ? "prefectures" : "regions") + ".json")
            .then(response => response.json())
            .then(data => {
                setGeojson(data);
                setGeojsonLoaded(true);
            });
    }, [mapType]);

    React.useEffect(() => {
        if (filterValues === null) return;
        if (!geojsonLoaded) return;

        async function fetchData() {
            setLoading(true);
            const variable = categories[activeCategory][selectedIndex];
            const [variables] = await Promise.all([myFetchVariables(variable)]);
            setLoading(false);
            const [newCharts, newTitle] = populateCharts([mapType], false, {[variable]: variables}, filterValues['Έτος'][yearIndex], null, filterState, filterValues);
            setCharts(newCharts);
            setTitle(newTitle);
        };

        fetchData();
    }, [geojsonLoaded, JSON.stringify(filterValues), activeCategory, selectedIndex, JSON.stringify(filterState), yearIndex]);

    React.useEffect(() => {
        return fetchChartsData(charts, setLoading, setChartsData);
    }, [JSON.stringify(charts)]);

    React.useEffect(() => {
        if (chartsData == null) return;
        let newGeoData = {};
        let min = 100;
        let max = 0;
        const variable = categories[activeCategory][selectedIndex];
        for (let i in filterValues[mapType]) {
            newGeoData[filterValues[mapType][i]] = ((chartsData["chart_" + 0 + "_series_" + i][variable]["value"] / chartsData["chart_" + 0 + "_series_" + i]["weighted"]["value"]) * 100);
            min = Math.min(newGeoData[filterValues[mapType][i]], min);
            max = Math.max(newGeoData[filterValues[mapType][i]], max);
        }
        setGeodata(newGeoData);
        scale.current = {min: floatFormatter(min), max: floatFormatter(max)};
    }, [JSON.stringify(chartsData)]);

    React.useEffect(() => {
        filtersChanged.current = checkFilterChange();
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        let data = {};
        data["mapType"] = mapType;
        data["activeCategories_" + activeCategory] = selectedIndex;
        for (let filter in filterState) {
            data["filterState_" + filter] = filterState[filter].join(",");
        }
        data["yearIndex"] = yearIndex;
        updateQuery(history, params, data);
    }, [mapType, JSON.stringify(filterState), activeCategory, selectedIndex, yearIndex]);

    if (geojson == null || geodata == null) return null;


    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Categories
                        showRemoveFilters={filtersChanged.current}
                        accordionExpanded={accordionExpanded}
                                activeCategories={{[activeCategory]: [selectedIndex]}}
                                categories={categories} classes={classes}
                                handleAccordionChange={handleAccordionChange}
                                handleMenuItemClick={handleMenuItemClick}
                                restoreDefaults={handleRestoreDefaults}
                    />
                </Grid>
                <Grid item md={9} xs={12}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between" spacing={5} className={classes.filterWrapper}>
                                <Grid item sm={2} xs={12}>Φίλτρα</Grid>
                                <Grid item sm={10} xs={12} style={{paddingTop: 0}}>
                                    {filterValues !== null && Object.keys(filterValues).map((filter, index) => {
                                        if (dofilters.includes(filter)) return (
                                            <Filter
                                                multiple={filter != 'Έτος'}
                                                key={index} filter={filter}
                                                    active={filter == "Έτος" || (filterState && filterState[filter])}
                                                    all={false}
                                                    onClick={(event) => handleFilterToggle(event, filter, "filter")}
                                                    selectAll={false}
                                                    removeAll={(event) => removeAll(event, filter, "filter")}
                                            />
                                        )
                                    })
                                    }
                                </Grid>
                            </Grid>

                            <Typography component="div"
                                        id={"my-map-container"}>
                                <h4 className={classes.heading}><img
                                    src={categoryLogo[activeCategory]}/>{title}
                                </h4>
                                <MapContainer doubleClickZoom={false} dragging={false} style={{height: "850px"}} attributionControl={false}
                                              center={[38.30, 24.09]} zoom={7}
                                              scrollWheelZoom={false} zoomControl={false}>
                                    <div style={{marginTop: "10px", marginLeft: "10px"}}>
                                        <Tabs value={mapType} onChange={handleChange} className={classes.tabs_map}>
                                            <Tab value={"Νομός"} label={"Νομοί"} />
                                            <Tab value={"Περιφέρεια"} label={"Περιφέρειες"}/>
                                        </Tabs>
                                    </div>
                                    <div style={{marginTop: "10px", marginLeft: "10px"}}>
                                        <Gradient colors={chartColorsArray[activeCategory]} scale={scale.current}></Gradient>
                                    </div>
                                    <div style={{marginTop: "10px", marginLeft: "10px"}}>Πηγή: ΕΠΙΨΥ</div>

                                    <IconButton
                                        className={"hideOnSave"}
                                        style={{position: "absolute", right: "5px", top: "5px"}}
                                        onClick={downloadMap}
                                        aria-label="delete"
                                    >
                                        <GetAppIcon/>
                                    </IconButton>
                                    <Choropleth
                                        identity={(feature) => feature.properties.name_greek + "" + geodata[feature.properties.name_greek]}
                                        data={geojson}
                                        valueProperty={(feature) => geodata[feature.properties.name_greek]}
                                        //visible={(feature) => feature.id !== active.id}
                                        colors={chartColorsArray[activeCategory]}
                                        steps={7}
                                        mode='e'
                                        style={mapStyle}
                                        onEachFeature={(feature, layer) => {
                                            layer.bindPopup(feature.properties.name_greek + " " + floatFormatter(geodata[feature.properties.name_greek]) + "%")
                                        }}
                                    />
                                </MapContainer>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FiltersPopper open={open} handleClose={handleClose} filterValues={filterValues} filterState={filterState}
                           yearIndex={yearIndex} handleFilterMenuItemClick={handleFilterMenuItemClick}/>

        </React.Fragment>
    );
}

export default MapTab;
