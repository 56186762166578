export default (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#F1F2F6',
        position: "relative",
        minHeight: "100vh"
    },
    bar: {
        background: '#061a2b',
        padding: '18px 0',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    footer: {
        background: '#061a2b',
        padding: '18px 0',
        color: '#fff',
        zIndex: 1000,
        '& a': {
            color: '#fff'
        },
        '& .MuiFormLabel-root': {
            color: '#fff'
        },
        '& .MuiInput-underline:before': {
           borderColor: '#fff'
        },
        '& .MuiInputBase-input': {
            color: '#fff'
        }
    },
    accordion: {
        background: '#EBEBEB',
        '& .MuiMenuItem-root': {
            color: '#262626',
            fontSize: '14px',
            lineHeight: '1.3em'
        },
        '& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root:hover': {
            color: '#13b6c9'
        },
        '& .MuiAccordionSummary-root': {
            borderRadius: "5px",
        },
        '& .MuiAccordionSummary-content': {
            margin: "10px 0"
        },
        '& .MuiAccordionSummary-root, & .MuiSvgIcon-root': {
            color: '#13b6c9'
        },
        '& .Mui-expanded.MuiAccordionSummary-root, & .Mui-expanded.MuiAccordionSummary-root .MuiSvgIcon-root, & .MuiAccordionSummary-root:hover, & .MuiAccordionSummary-root:hover .MuiSvgIcon-root': {
            color: '#FFF',
            background: '#13b6c9'
        },
        '& .image-wrapper': {
            float: "left"
        },
        '& .image-wrapper .active': {
            display: "none"
        },
        '& .Mui-expanded.MuiAccordionSummary-root .image-wrapper .default, & .MuiAccordionSummary-root:hover .image-wrapper .default': {
            display: "none"
        },
        '& .Mui-expanded.MuiAccordionSummary-root .image-wrapper .active, & .MuiAccordionSummary-root:hover .image-wrapper .active': {
            display: "inline-block"
        },
        '& .title-wrapper': {
            marginLeft: '10px',
            display: "flex",
            alignItems: 'center'
        },
        '& .MuiList-root': {
            maxHeight: '200px',
            overflow: 'auto'
        },
        '& .MuiList-root .MuiListItem-root': {
            whiteSpace: 'normal'
        },
        '& .MuiList-root::-webkit-scrollbar': {
            background: '#242a3c'
        },
        '& .MuiList-root::-webkit-scrollbar-thumb': {
            background: '#13b6c9',
            borderRadius: '5px'
        }
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 600,
        height: 600,
        margin: '0 auto'
    },
    heading: {
        color: '#13b6c9',
        textAlign: 'center',
        position: 'relative',
        padding: '20px 40px',
        margin: '0',
        '& img': {
            position: 'absolute',
            left: 0,
            top: '7px'
        }
    },
    title: {
        fontSize: "18px"
    },
    intro: {
        color: "#efefef",
        fontSize: "13px",
        margin: 0
    },
    absoluteFab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    filterWrapper: {
        backgroundColor: "rgba(255, 255, 255,0.13)",
        borderRadius: "0px",
        marginBottom: "12px",
        marginTop: "0",
        '&:not(:last-child)': {
            borderBottom: "1px solid #D3D3D3"
        },
        '& .MuiButtonBase-root': {
            border: "none!important",
            textTransform: "none",
            background: "#fff"
        },
        '& .MuiButton-containedPrimary': {
            background: '#13b6c9'
        },
    },
    tabs_map: {
        marginTop:"15px",
        '& .MuiTab-root': {
            textTransform: 'none'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#13b6c9'
        },
    },
    tabs: {
        marginTop:"15px",
        '& .MuiTab-root': {
            textTransform: 'none'
        },
        '& .MuiButtonBase-root': {
            background: '#EBEBEB',
            borderRadius: "5px",
            color: '#13b6c9',
            margin: '0 5px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
        },
        '& .MuiButtonBase-root.Mui-selected, & .MuiButtonBase-root:hover': {
            background: '#13b6c9',
            color: '#fff'
        },
        '& .MuiTabs-indicator': {
            display: "none",
        },

    },
    chartWrapper: {
        borderWidth: "1px",
        borderColor: '#cfe8fc',
        '& .MuiGrid-item': {
            position: "relative"
        },
        '& div .MuiButtonBase-root': {
            position: "absolute",
            right: "30px",
            top: "25px",
            color: "#a1a1a1",
            //border: "1px solid #ebebeb",
            padding: "6px"
        },
        '& canvas': {
            backgroundColor: "#fff",
            borderRadius: "5px",
            paddingRight: 0,
            boxShadow: "1.902px 0.618px 4.9px 0.1px rgba(0,0,0,0.16)"
        }
    },
    removeFilters: {
        position: "absolute",
        zIndex: 1000,
        top: "-40px",
        left: "calc(50% - 114px)",
        textTransform: "none"
    }
});