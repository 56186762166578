import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ListItemText from "@material-ui/core/ListItemText";
import Popper from "@material-ui/core/Popper";
import React from "react";

function FiltersPopper(props) {
    const {open, handleClose, filterValues, filterState, clusterState, yearIndex, handleFilterMenuItemClick} = props;

    return (<Popper open={open !== false} anchorEl={open !== false ? open.element : null} role={undefined}
                    transition disablePortal style={{zIndex: 500}}>
        {({TransitionProps, placement}) => (
            <Grow
                {...TransitionProps}
                style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
            >
                <Paper style={{maxHeight: "500px", overflowY: "auto"}}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {filterValues[open.item] && filterValues[open.item].map((option, index) => {
                                const tempSelectedIndexes = open.type == "filter" ? filterState && filterState[open.item] ? filterState[open.item] : [] : clusterState ? clusterState["indexes"] : [];
                                const isOpenActive = open.type == "filter" ? filterState && filterState[open.item] ? true : false : clusterState ? clusterState["item"] == open.item : false;
                                return (
                                    <MenuItem
                                        key={option + "-" + index}
                                        selected={(open.item == "Έτος" && yearIndex == index) || (open.item != "Έτος" && isOpenActive && tempSelectedIndexes.includes(index))}
                                        onClick={(event) => handleFilterMenuItemClick(event, index, open)}
                                    ><ListItemIcon>
                                        {((open.item == "Έτος" && yearIndex == index) || (open.item != "Έτος" && isOpenActive && tempSelectedIndexes.includes(index))) &&
                                        <CheckBoxIcon/>}
                                        {!((open.item == "Έτος" && yearIndex == index) || (open.item != "Έτος" && isOpenActive && tempSelectedIndexes.includes(index))) &&
                                        <CheckBoxOutlineBlankIcon/>}
                                    </ListItemIcon>
                                        <ListItemText primary={option}/>
                                    </MenuItem>
                                )
                            })}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>);
}

export default FiltersPopper;