import {chartColors} from "./Settings";
import {
    useLocation,
    useHistory
} from "react-router-dom";

export const myFetchVariables = async (field, callback) => {
    try {
        const [buckets] = await Promise.all([fetch(process.env.REACT_APP_SERVICE_URL + "_search?size=0", {
                //cors: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    "aggs": {
                        "variables": {
                            "terms": {
                                "size": 100000,
                                "field": field, "order": {"_term": field == "Έτος" ? "desc" : "asc"}
                            },
                        }
                    }
                })
            }).then(res => res.json())]
        );

        return buckets.aggregations.variables.buckets.map(item => item.key);
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const floatFormatter = (value) => {
    if (isNaN(value)) return 0;
    return value.toLocaleString("el-GR", {minimumFractionDigits: 1, maximumFractionDigits: 1});
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const updateQuery = (history, params, data) => {
    for (let param in data) {
        const value = data[param];
        params.delete(param);
        params.append(param, value);
    }
    history.push({search: params.toString()})
};


export const useQueryVariable = (variable, defaultValue, type) => {
    const query = useQuery();
    if (type == "object") {
        let data = {};
        for (let param of query.keys()) {
            if (param.includes(variable)) {
                data[param.replace(variable + "_", "")] = query.get(param).split(",").map(function (item) {
                    return parseInt(item)
                });
            }
        }
        return Object.keys(data).length ? data : defaultValue;
    }

    let value = query.get(variable);
    if (!value) return defaultValue;

    if (type === "integer") {
        return parseInt(value);
    }
    return value;
};

function fixfieldname(field) {
    return field;
}

export const populateCharts = (doCharts, doTotal, variables, year, clusterState, filterState, filterValues) => {

    let newCharts = [];
    let filterGroups = [0];

    let filterVariables = {};
    if (filterState) {
        for (let filter in filterState) {
            filterVariables[filter] = [];
            for (let j in filterState[filter]) {
                filterVariables[filter].push(filterValues[filter][filterState[filter][j]]);
            }
        }
    }

    const clusterVariables = [];
    if (clusterState) {
        for (let j in clusterState["indexes"]) {
            clusterVariables.push(filterValues[clusterState["item"]][clusterState["indexes"][j]]);
        }
    }

    for (let i in doCharts) {
        if (clusterState && clusterState["item"] != doCharts[i]) {
            filterGroups = [];
            for (let f in clusterVariables) {
                filterGroups.push(clusterVariables[f]);
            }
        }

        let series = [];
        for (let f in filterGroups) {

            for (let j in filterValues[doCharts[i]]) {
                let filtersArray = [];
                let cluster = false;
                if (filterState) {
                    for (let filter in filterVariables) {
                        filtersArray.push(createNewFilter(filter, filterVariables[filter], filterValues[filter]));
                    }
                }
                if (clusterState && clusterState["item"] != doCharts[i]) {
                    cluster = createNewFilter(clusterState["item"], [clusterVariables[f]], filterValues[clusterState["item"]]);
                } else if (clusterState && clusterState["item"] === doCharts[i]) {
                    //if (!clusterVariables.includes(filterValues[doCharts[i]][j]))
                    continue;
                }

                if (doCharts[i] == "Γεωγραφικό στρώμα" && clusterState && ["Νομός", "Περιφέρεια"].includes(clusterState["item"])) {
                    continue;
                }
                let allFilters = [];
                if (year != null) {
                    allFilters.push(createNewFilter("Έτος", [year], filterValues["Έτος"]));
                }
                allFilters.push(createNewFilter(doCharts[i], [filterValues[doCharts[i]][j]], filterValues[doCharts[i]]));
                allFilters = allFilters.concat(filtersArray);

                for (let variable in variables) {
                    series.push(createNewSeries(filterValues[doCharts[i]][j], variable, ["Ναι"], variables[variable], variable, allFilters, cluster, chartColors[doCharts[i]] && chartColors[doCharts[i]][filterValues[doCharts[i]][j]] ? chartColors[doCharts[i]][filterValues[doCharts[i]][j]] : "#000"));
                }
            }
        }

        newCharts.push({
            'title': Object.keys(variables)[0],
            "subtitle": year !== null ? doCharts[i] : "",
            'filters': (year !== null ? ('Έτος: ' + year) : "") + (filterState ? (year !== null ? ", " : "") + Object.keys(filterVariables)[0] + ": " + filterVariables[Object.keys(filterVariables)[0]].join(", ") : ""),
            'series': series
        });
    }


    if (doTotal) {
        let series = [];
        for (let f in filterGroups) {
            let cluster = false;
            let filtersArray = [createNewFilter("Έτος", [year], filterValues["Έτος"])];
            let title = 'Σύνολο';
            let color = '#b8912f';
            if (filterState) {
                filtersArray.push(createNewFilter(Object.keys(filterVariables)[0], filterVariables[Object.keys(filterVariables)[0]], filterValues[Object.keys(filterVariables)[0]]));
            }

            if (clusterState) {
                cluster = createNewFilter(clusterState["item"], [clusterVariables[f]], filterValues[clusterState["item"]]);
            }
            for (let variable in variables) {
                series.push(createNewSeries(title, variable, ["Ναι"], variables[variable], "Group 1", filtersArray, cluster, color));
            }
        }
        newCharts = [{
            'title': Object.keys(variables)[0],
            "subtitle": null,
            'filters': 'Έτος: ' + year + (filterState ? ", " + Object.keys(filterVariables)[0] + ": " + filterVariables[Object.keys(filterVariables)[0]].join(", ") : ""),
            'series': series
        }, ...newCharts];
    }
    const title = Object.keys(variables)[0] + ", " + year + (year === 2015 || year === 2009 ? "* " : "") + (clusterState ? ", " + clusterState["item"] + ": " + clusterVariables.join(", ") : "") + (filterState ? ", " + Object.keys(filterVariables)[0] + ": " + filterVariables[Object.keys(filterVariables)[0]].join(", ") : "");
    return [newCharts, title];
};

export const createNewChart = (title, series) => {
    return {'title': title, 'series': series};
};

export const createNewSeries = (title, variable, values, available_values, group, filters, cluster, color) => {
    return {
        'title': title,
        'maxBarThickness': 120,
        'variable': variable,
        'variable_available_values': available_values,
        'color': color,
        'values': values,
        'group': group,
        'filters': filters,
        'cluster': cluster
    };
};

export const createNewFilter = (filter, values, available_values) => {
    return {
        'filter': filter,
        'filter_values': values,
        'filter_available_values': available_values
    }
};

export const fetchChartsData = (charts, setLoading, setChartsData) => {
    setLoading(true);
    let aggs = {};
    for (let i in charts) {
        for (let j in charts[i].series) {
            const series = charts[i].series[j];
            let agg = {};
            if (series.filters.length) {
                let bools = [];
                for (let f in series.filters) {
                    const filter = series.filters[f];
                    if (filter.filter && filter.filter_values.length) {
                        bools.push({"terms": {[filter.filter]: filter.filter_values}});

                    }
                }
                if (series.cluster) {
                    bools.push({"terms": {[series.cluster.filter]: series.cluster.filter_values}});

                }
                if (bools.length) {
                    agg = {
                        "filter": {
                            "bool": {
                                "must": bools
                            }
                        }
                    };
                } else {
                    agg = {"filter": {"match_all": {}}}
                }
            } else {
                agg = {"filter": {"match_all": {}}}
            }

            if (series.variable) {
                agg["aggs"] = {
                    [series.variable]: {
                        "sum": {
                            "script": {
                                "source": 'doc["' + fixfieldname(series.variable) + '"].size() > 0 && doc["' + fixfieldname(series.variable) + '"].value == "Ναι" ? 1 * doc.weightyear.value : 0'
                            }
                        }
                    },
                    ["weighted"]: {
                        "sum": {
                            "script": {
                                "source": 'doc["' + fixfieldname(series.variable) + '"].size() > 0 ? 1 * doc.weightyear.value : 0'
                            }
                        }
                    }
                };
            }
            aggs["chart_" + i + "_series_" + j] = agg;
        }
    }
    if (Object.keys(aggs).length === 0) return;

    fetch(process.env.REACT_APP_SERVICE_URL + "_search?size=0", {
            //cors: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                "aggs": aggs
            })
        }
    )
        .then(res => res.json())
        .then(
            (result) => {
                setLoading(false);
                setChartsData(result.aggregations);
                //callback(result.aggregations.variables.buckets.map(item => item.key));
            }, (error) => {
                setLoading(false);
            });
};

export const downloadChart = (chart) => {
    let a = document.createElement('a');
    let ctx = document.getElementById('chart-download')
    ctx.height = (ctx.width / chart.canvas.width) * chart.canvas.height;
    //instantiate the chart
    const dynamic_chart = new chart.constructor(ctx, {
        type: chart.config.type,
        data: chart.config.data,
        options: chart.config.options
    });

    a.download = 'chart.png';

    setTimeout(function () {
        // Trigger the download
        a.href = dynamic_chart.canvas.toDataURL("image/png");

        a.click();
    }, 1000);
};

function updateMinMax(scale, data) {
    var dlen = data.length;
    var i, value, j, ilen;
    for (j = 0; j < dlen; j++) {
        ilen = data[j].data.length;
        for (i = 0; i < ilen; ++i) {
            value = data[j].data[i];
            scale.max = Math.max(scale.max, value);
        }
    }
    var rounded = Math.ceil(scale.max / 10) * 10;
    if (rounded - scale.max < 5) rounded += 10;
    scale.max = Math.min(100, rounded);
}

export const generateChartOptions = (chart, datasets, type) => {

    const scaleMinMax = {min: 0, max: 0};
    updateMinMax(scaleMinMax, datasets);
    const options = {
        // container for watermark options
        plugins: {
            datalabels: {
                color: type == "line" ? 'black' : 'white',
                font: {
                    weight: 'bold',
                    size: type == "line" ? 14 : 18
                },
                anchor: 'end',
                align: 'bottom',
                formatter: function (value, context) {
                    if (value !== null)
                        return floatFormatter(value);
                }
            },
            chartJsPluginSubtitle: {
                display: true,
                anchor: true,
                position: "bottom",
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = (chart.subtitle !== null && type !== "line" ? (chart.subtitle + " -> ") : "") + data.datasets[tooltipItem.datasetIndex].label + ": ";
                    let value = type === "line" ? data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index][0] : tooltipItem.yLabel;
                    if (value !== null) {
                        label += floatFormatter(value) + "%";
                        return label;
                    } else {
                        return null;
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: chart.title,
        },
        anchor: {
            display: true,
            text: 'Πηγή: ΕΠΙΨΥ'
        },
        legendTitle: {
            display: chart.subtitle ? true : false,
            text: "" + chart.subtitle
        },
        subtitle: {
            display: true,
            text: chart.filters
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 6,
                    min: scaleMinMax.min,
                    max: scaleMinMax.max,
                },
            }],
            xAxes: [{
                offset: true,
                ticks: {
                    display: true,
                }
            }],
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 30
            }
        }
    };
    return options;
};

export const generateChartData = (chart, index, chartsData, keepEmpty) => {
    let sets = {};
    let backgrounds = [];

    let groups = {};

    let isTotal = false;
    for (let i in chart.series) {
        const series = chart.series[i];
        if (series.title == "Σύνολο") {
            isTotal = true;
        }
        groups[series.group] = 1;
    }

    const addGroup = Object.keys(groups).length > 1;

    for (let i in chart.series) {
        const series = chart.series[i];
        for (let j in series.values) {
            const key = (series.cluster ? (!["Περιφέρεια", "Νομός"].includes(series.cluster["filter"]) ? series.cluster["filter"] + " " : "") + " " + series.cluster["filter_values"][0] : addGroup ? series.group : "");
            // + series.values[j] + " " + series.group
            if (!sets[key]) sets[key] = [];
            sets[key].push(i);
        }
    }

    let labels = Object.keys(sets);
    let datasets = [];

    for (let i in chart.series) {
        let countEmpty = 0;
        const series = chart.series[i];
        let seriesData = [];
        for (let j in labels) {
            let value = null;
            if (sets[labels[j]].includes(i)) {
                if (chartsData && chartsData["chart_" + index + "_series_" + i] && chartsData["chart_" + index + "_series_" + i][series.variable]) {
                    if (chartsData["chart_" + index + "_series_" + i].doc_count === 0 || chartsData["chart_" + index + "_series_" + i]["weighted"]["value"] === 0) {
                        countEmpty++;
                    } else {
                        value = ((chartsData["chart_" + index + "_series_" + i][series.variable]["value"] / chartsData["chart_" + index + "_series_" + i]["weighted"]["value"]) * 100);
                    }
                }
            }
            seriesData.push(value);
        }
        if (seriesData.length == countEmpty && !keepEmpty) continue;
        let color = typeof series.color === 'string' ? series.color : 'rgba(' + series.color.rgb[0] + ',' + series.color.rgb[1] + ',' + series.color.rgb[2] + ',' + series.color.alpha + ')';
        datasets.push({
            label: series.title,
            data: seriesData,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            maxBarThickness: series.maxBarThickness
        });
    }
    let groupedDatasets = {};

    for (let d in datasets) {
        if (!groupedDatasets[datasets[d].label]) {
            groupedDatasets[datasets[d].label] = [];
        }
        groupedDatasets[datasets[d].label].push(datasets[d]);
    }


    let newDatasets = [];
    for (let gd in groupedDatasets) {
        let newValues = [];
        for (let gdd in groupedDatasets[gd]) {
            newValues.push(groupedDatasets[gd][gdd]["data"][gdd]);
        }

        groupedDatasets[gd][0]["data"] = newValues;
        newDatasets.push(groupedDatasets[gd][0]);
    }


    if (isTotal) {
        let combined = [];
        for (let l in labels) {
            combined.push([labels[l], newDatasets[0].data[l]]);
        }
        combined.sort(function(elema, elemb){
            return elema[1] - elemb[1];
        });

        labels = [];
        let values = [];
        for (let c in combined) {
            labels.push(combined[c][0]);
            values.push(combined[c][1]);
        }
        newDatasets[0].data = values;
    }
    let data = {
        labels: labels,
        datasets: newDatasets
    };

    return data;
};